import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { JProject } from "jmapcloud-types"
import { JStudioState } from "./model"

const initialStudioState: JStudioState = {
  project: null
}

interface ProjectPayload {
  project: JProject
}

const studioSlice = createSlice({
  name: "studio",
  initialState: initialStudioState,
  reducers: {
    setProject(state, action: PayloadAction<ProjectPayload>) {
      state.project = action.payload.project
    }
  }
})

export const { setProject } = studioSlice.actions
export default studioSlice.reducer
